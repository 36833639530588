<template>
    <div>
     
      <v-card flat :loading="loading">
        <v-card-text>
          <v-row justify="end" align="center">
              <v-col cols="12" sm="6" class="mt-0 pt-0">
                <v-chip
                  class="mx-1"
                  small
                  outlined
                  v-for="(date, index) in quickSelectDates"
                  :key="index"
                  :value="date.value"
                  @click="selectDate(date.value)"
                  :color="params.selectedDate == date.value ? 'teal' : ''"
                >
                  <v-icon
                    left
                    v-if="params.selectedDate == date.value"
                    color="teal"
                    small
                    >check</v-icon
                  >
                  {{ date.text }}</v-chip
                >
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="blue"
                      class="mx-1"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      small
                      ><v-icon small left>date_range</v-icon>{{ dateText }}
                      <v-icon small right>expand_more</v-icon></v-chip
                    >
                  </template>
                  <v-date-picker
                    v-model="params.dates"
                    range
                    color="primary"
                    show-week
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"> Cancel </v-btn>
                    <v-btn text color="primary" @click="setDateFilter">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-chip
                  :key="dateKey"
                  :color="params.dateType == 'ETD' ? 'teal' : 'blue-grey'"
                  class="mx-1"
                  small
                  @click="changeDateType()"
                >
                  <v-icon small left>event</v-icon> Date Type:
                  {{ params.dateType }}
                </v-chip>
                <v-checkbox label="Hide Cancelled Bookings" dense v-model="params.hideCancelled" @change="getOrders()"></v-checkbox>

              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                <v-chip
                style="height: 40px"
                outlined
                small
                class="pr-1 text-center"
              >
                <v-text-field
                  placeholder="Search"
                  class="mb-1"
                  hide-details
                  rounded
                  clearable
                  dense
                  style="width: 400px"
                  v-model="search"
                ></v-text-field>
              </v-chip>
              <v-row class="my-3" justify="end" :key="updateFilterValue">
              <div :key="updateFilterValue">
                <v-chip
                  color="red"
                  class="mx-1"
                  small
                  @click="resetFilters()"
                  v-if="filteredValues.length > 0"
                >
                  <v-icon small left>filter_alt_off</v-icon> Clear Filters
                </v-chip>
                <v-chip
                  class="mx-1"
                  close
                  small
                  @click:close="
                    addToFilter(filterValue.value, filterValue.field)
                  "
                  v-for="(filterValue, index) in filteredValues"
                  :key="index"
                  >{{ filterValue.value }}</v-chip
                >
              </div>
            </v-row>
              </v-col>
            </v-row>
          <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0">
            
            <div :style="{ width: tableWidth }" class="mt-2" v-if="displayData.length > 0">
              <table :width="'100%'" >
                <tr class="mx-0 px-0">
                  <draggable
                    v-model="selectedHeaders"
                    tag="tr"
                    :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                    :animation="200"
                    @change="updateUserLayout()"
                    ghost-class="ghost-card"
                    @start="columnDrag = true"
                    @end="columnDrag = false"
                  >
                    <td
                      class="text-center mx-0 px-0"
                      style="
                        cursor: grab;
                        border-right: 1px solid grey;
                        min-height: 20px;
                        min-width: 20px;
                      "
                      v-for="(header, index) in headers"
                      :key="index"
                      :width="header.width ?? '150px'"
                    >
                      <div>
                        <v-row align="center" justify="center" class="py-2">
                         
                          <b style="font-size: 12px">{{ header.text }}</b>
                          <v-btn
                            v-if="!header.disableSort"
                            class="ml-1"
                            icon
                            x-small
                            @click="changeSortBy(header.value)"
                          >
                            <v-icon
                              v-if="sortBy != header.value"
                              small
                              color="grey"
                              >arrow_upward</v-icon
                            >
                            <v-icon v-else-if="sortDesc" small
                              >arrow_downward</v-icon
                            >
                            <v-icon v-else small>arrow_upward</v-icon>
                          </v-btn>
                          <v-badge
                            v-if="!header.disableFilter"
                            :content="
                              filters[header.value]
                                ? filters[header.value].length
                                : false
                            "
                            :value="
                              filters[header.value] &&
                              filters[header.value].length > 0
                            "
                            color="primary"
                            offset-y="10"
                          >
                            <v-menu offset-y left :close-on-content-click="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                  color="grey"
                                  icon
                                  ><v-icon small>filter_alt</v-icon></v-btn
                                >
                              </template>
                              <v-card
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-card-text>
                                  <v-list
                                    dense
                                    style="
                                      background: var(--v-modal-base) !important;
                                      border-radius: 20px;
                                    "
                                  >
                                    <v-text-field
                                      hide-details
                                      rounded
                                      dense
                                      outlined
                                      clearable
                                      prepend-inner-icon="search"
                                      placeholder="Search"
                                      autofocus
                                      v-model="searchHeaders[header.value]"
                                      @input="updateSearchKey()"
                                    ></v-text-field>
                                    <v-list
                                      dense
                                      style="
                                        max-height: 50vh;
                                        overflow-y: auto;
                                        background: var(
                                          --v-modal-base
                                        ) !important;
                                      "
                                      :key="searchKey"
                                    >
                                      <v-list-item
                                        v-for="(item, index) in headerOptions(
                                          header.value
                                        )"
                                        :key="index"
                                      >
                                        <v-list-item-action class="pr-0">
                                          <v-checkbox
                                            v-model="filters[header.value]"
                                            :value="item.value"
                                            hide-details
                                            dense
                                            @change="calculateFilteredResults()"
                                          ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ item.name }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-list-item
                                        v-if="
                                          headerOptions(header.value).length == 0
                                        "
                                      >
                                        <v-list-item-content class="text-center">
                                          <span style="font-size: 12px"
                                            >No results found</span
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-badge>
                        </v-row>
                      </div>
                    </td>
                  </draggable>
                </tr>
              </table>
              <v-divider></v-divider>
              <div
                class="mt-1 pt-1"
                :style="{ width: '100%' }"
                v-if="displayData.length > 0"
              >
                <v-virtual-scroll
                  v-if="displayData.length > 0"
                  :bench="10"
                  :items="displayData"
                  :height="calculatedHeight"
                  :width="'100%'"
                  item-height="35"
  
                >
                  <template v-slot:default="{ item }">
                    <div
                      class="text-center my-0 py-0"
                      :style="{
                        display: 'flex',
                        'flex-direction': 'row',
                        width: '100%',
                        'background-color':
                        item.id && selectedOrderItem && selectedOrderItem.id == item.id
                            ? 'rgba(12, 97, 24, 0.6)'
                            : 'transparent',
                      }"
                    >
                    <div v-if="item.displayType == 'header'" style="width: 100%">
                      <td
                        class="text-center mt-0 mb-0 pb-0 mr-0 pr-0"
                      >
                      {{ item.title }} <v-chip class="mx-1" outlined style="border: none" small>
                        <v-icon small left color="teal">label</v-icon>
                        Bookings <v-chip right x-small class="ml-2">{{ item.orders }}</v-chip>
                      </v-chip>
                      <v-chip class="mx-1" small  outlined style="border: none">
                        <v-icon small left color="blue-grey">widgets</v-icon>
                        Containers <v-chip x-small class="ml-2" right>{{ item.containers }}</v-chip>
                      </v-chip>
                      </td>
                    </div>
                    <div v-else :style="{width: '100%'}" class="my-0 py-0">
                      <td
                        v-for="(header, index) in headers"
                        :key="index"
                        :style="{ width: header.width ?? '150px' }"
                        class="text-center my-0 py-0"
                      >
                        <div v-if="header.value == 'actions'" >
                           <v-btn icon @click="viewBookingDetail(item.systemReference)" small>
                             <v-icon small>launch</v-icon>
                           </v-btn>
                        </div>
                        <div v-else-if="header.value == 'userFullName'" >
                           <v-tooltip top v-if="item.orderUser">
                           <template v-slot:activator="{ on }">
                            <v-avatar size="24" class="ml-1" v-on="on" :color="item.orderUser.avatar ? 'white': 'secondary'" style="cursor:pointer"  @click="addToFilter(item.userFullName, 'userFullName')">
                             <v-img v-if="item.orderUser.avatar" contain :src="item.orderUser.avatar"></v-img>
                             <h4 v-else>{{ item.orderUser.firstname.charAt(0) }}</h4>
                            </v-avatar>
                           </template>
                           <span style="font-size: 12px">{{
                             item.orderUser.firstname
                           }} {{  item.orderUser.surname }}</span>
                         </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'orderStatus'" class="text-center" >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="statusColor(item.orderStatus)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.orderStatus, 'orderStatus')"
                              >
                                <v-icon>{{ statusIcon(item.orderStatus) }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.orderStatus)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'state'" class="text-center">
                          <v-tooltip top v-if="item.state">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="bookingStatusColor(item.state)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.state, 'state')"
                              >
                                <v-icon>{{ bookingStatusIcon(item.state) }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.state)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'type'" 
                          class="text-center"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="bookingTypeColor(item.type)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.type, 'type')"
                              >
                                <v-icon>{{ bookingTypeIcon(item.type) }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.type)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'vesselVoyage'" 
                          class="text-left"
                        >
                          <v-chip
                            v-if="item.vesselVoyage"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.vesselVoyage, 'vesselVoyage')
                            "
                            style="border: none; font-size: 11px"
                            ><span>{{ item.vesselVoyage }}</span>
                          </v-chip>
                        </div>
                        <div
                          v-else-if="header.value == 'portOfLoadValue'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.portOfLoadValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.portOfLoadValue,
                                    'portOfLoadValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.portOfLoadValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.portOfLoadCity
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'finalDestinationValue'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.finalDestinationValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.finalDestinationValue,
                                    'finalDestinationValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.finalDestinationValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.finalDestinationCity
                            }}</span>
                          </v-tooltip>
                        </div>
                     
                        <div
                          v-else-if="header.value == 'consigneeName'" 
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.consignee">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.consignee"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.consigneeName, 'consigneeName')
                                "
                                v-on="on"
                                style="border: none; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.consignee && item.consignee.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.consignee.logo"
                                    :src="item.consignee.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.consigneeName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.consigneeName }}
                              </v-chip>
                            </template>
                            <span>{{ item.consignee.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'buyerName'" 
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.buyer">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.buyer"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.buyerName, 'buyerName')
                                "
                                v-on="on"
                                style="border: none; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.buyer && item.buyer.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.buyer.logo"
                                    :src="item.buyer.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.buyerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.buyerName }}
                              </v-chip>
                            </template>
                            <span>{{ item.buyer.name }}</span>
                          </v-tooltip>
                        </div>
                        
                        <div
                          v-else-if="header.value == 'forwarderName'" 
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.forwarder">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.forwarder"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.forwarderName, 'forwarderName')
                                "
                                v-on="on"
                                style="border: none; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.forwarder && item.forwarder.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.forwarder.logo"
                                    :src="item.forwarder.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.forwarderName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.forwarderName }}
                              </v-chip>
                            </template>
                            <span>{{ item.forwarder.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'transporterName'"
                          :class="item.transporter ? 'text-left' : 'text-center'"
                        >
                          <v-tooltip top v-if="item.transporter">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.transporter"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(
                                    item.transporterName,
                                    'transporterName'
                                  )
                                "
                                style="border: none; max-width: 100%; max-height: 5vh; height:auto; overflow: hidden; "
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.transporter && item.transporter.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.transporter.logo"
                                    :src="item.transporter.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.transporterName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.transporterName }}
                              </v-chip>
                            </template>
                            <span>{{ item.transporter.name }}</span>
                          </v-tooltip>
                          <v-btn
                            color="grey"
                            small
                            icon
                            v-else
                            @click="assignContainers([item])"
                            ><v-icon small>add_circle_outline</v-icon></v-btn
                          >
                        </div>
  
                        <div
                          v-else-if="header.value == 'customerName'" 
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.orderCustomer">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.orderCustomer"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.customerName, 'customerName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.orderCustomer && item.orderCustomer.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.orderCustomer.logo"
                                    :src="item.orderCustomer.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.customerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.customerName }}
                              </v-chip>
                            </template>
                            <span>{{ item.orderCustomer.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shipperName'"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.shipper">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.shipper"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.shipperName, 'shipperName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.shipper && item.shipper.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.shipper.logo"
                                    :src="item.shipper.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.shipperName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.shipperName }}
                              </v-chip>
                            </template>
                            <span>{{ item.shipper.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shippingLineName'" class="text-left" 
                        >
                          <v-chip
                            v-if="item.shippingLine"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(
                                item.shippingLineName,
                                'shippingLineName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.shippingLine && item.shippingLine.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.shippingLine.logo"
                                :src="item.shippingLine.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shippingLineName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shippingLineName }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'transportStatus'">
                          <v-menu offset-y v-if="item[header.value]">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                small
                                class="primaryText--text"
                                :color="getStatusColor(item.transportStatus)"
                                v-on="on"
                                style="border: none"
                              >
                                {{ item[header.value] }}
                                <v-icon small right>expand_more</v-icon>
                              </v-chip>
                            </template>
                            <v-list style="overflow: hidden" dense>
                              <v-list-item
                                v-for="(status, index) in transportStatuses"
                                :key="index"
                                style="height: 35px"
                                @click="setStatus(item, status)"
                              >
                                <v-list-item-action>
                                  <v-icon :color="status.color"
                                    >fiber_manual_record</v-icon
                                  >
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ status.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div v-else-if="header.value == 'etd'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'vesselLegs'" >
                          <v-btn
                            v-if="item.sailingSchedule"
                            icon
                            small
                            outlined
                            @click="viewBookingLegs(item)"
                            :color="
                              item.sailingSchedule.legs == 1 ? 'success' : 'blue'
                            "
                            >{{ item.sailingSchedule.legs }}</v-btn
                          >
                        </div>
                        <div v-else-if="header.value == 'numContainers'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            @click="addToFilter(item[header.value], header.value)"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'eta'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>
  
                        <div v-else-if="header.value == 'movementType'" >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.movementType, header.value)
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  :color="
                                    item.movementType == 'EXPORT'
                                      ? 'deep-orange'
                                      : item.movementType == 'IMPORT' ? 'blue':'green'
                                  "
                                  left
                                >
                                  <h4 style="color: white">
                                    {{ item.movementType.charAt(0) }}
                                  </h4>
                                </v-avatar>
                                {{ item.orderNo }}
                              </v-chip>
                            </template>
                            <span
                              >{{
                                $Format.capitalizeFirstLetter(item.movementType)
                              }}
                              Booking</span
                            >
                          </v-tooltip>
                        </div>
                        <div v-else >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                      </td>
                    </div>
                    </div>
                  
  
                    <v-divider class="my-0 py-0 mt-1"></v-divider>
                  </template>
                </v-virtual-scroll>
              </div>
            </div>
            <div class="container" v-if="!loading && displayData.length == 0">
              <h3 style="color: grey">No Results Found</h3>
            </div>
            <div class="container" v-else-if="loading && displayData.length == 0">
              <valhalla-loading height="500px" />
            </div>
          </v-card-text>
        </v-card-text>
      </v-card>

      <v-dialog v-model="previewModal" width="1500px" :fullscreen="$vuetify.breakpoint.mobile">
    <PreviewBooking :bookingRequest="bookingRequestItem" :containers="containers" :containerTypes="containerTypes" @close="previewModal=false"/>
    </v-dialog>

    <v-dialog v-model="viewBookingInformation" width="90vw">
        <BookingInformation 
          :bookingId="selectedBooking.id"
          :bookingInfo="selectedBooking"
          :systemReference="selectedBooking.systemReference"
          @close="viewBookingInformation = false"
        />
      </v-dialog>
    </div>
  </template>
  <script>
import BookingInformation from "../ShipmentOverview/Components/BookingInformation.vue";

  import dateFormat from 'dateformat'
  import draggable from "vuedraggable";
  import * as moment from 'moment'
  import PreviewBooking from './PreviewBooking.vue'
  export default {
    components: {
      BookingInformation,
      draggable,
      PreviewBooking
    },
    data: () => ({
      bookingRequestItem: {},
      bookingStatus: [
        { name: "Draft", icon: "edit_note", color: "#F57C00" },
        { name: "Pending", icon: "pending", color: "#009688" },
        { name: "Requested", icon: "verified", color: "#2196F3" },
        { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
        { name: "Ghost", icon: "help", color: "#607D8B" },
        { name: "Cancelled", icon: "cancel", color: "#F44336" },
        { name: "Rejected", icon: "warning", color: "#F44336" },
      ],
      columnDrag: false,
      containerTypes: [],
      containers: [],
      dateKey: 300,
      dateMenu: false,
      filteredResults: [],
      filters: null,
      filteredValues: [],
      headers: [
        {
          text: "Action",
          align: "center",
          disableSort: true,
          disableFilter: true,
          value: "actions",
          width: "100px",
        },
        // {
        //   text: "User",
        //   align: "center",
        //   disableSort: false,
        //   disableFilter: false,
        //   value: "userFullName",
        //   width: "100px",
        // },
        
        // {
        //   text: "Status",
        //   value: "orderStatus",
        //   align: "center",
        //   width: "100px",
        //   filterable: true,
        // },
        {
          text: "Booking",
          value: "state",
          align: "center",
          width: "100px",
          filterable: true,
        },
        {
          text: "Order No",
          value: "movementType",
          align: "center",
          filterable: true,
        },
        {
          text: "Containers",
          value: "numContainers",
          align: "center",
          width: "125px",
        },
        {
          text: "Vessel",
          value: "vesselVoyage",
          align: "center",
          width: "200px",
        },
        // {
        //   text: "Customer",
        //   value: "customerName",
        //   width: "220px",
        //   filterable: true,
        // },
        {
          text: "Shipper",
          value: "shipperName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Buyer",
          value: "buyerName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Consignee",
          value: "consigneeName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Forwarder",
          value: "forwarderName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Carrier",
          value: "shippingLineName",
          align: "left",
          width: '120px'
        },
        {
          text: "Week",
          value: "sailingWeek",
          align: "center",
          width: "120px",
        },
        {
          text: "POL",
          value: "portOfLoadValue",
          align: "center",
          width: "100px",
        },
        {
          text: "ETD",
          value: "etd",
          align: "center",
          width: "100px",
        },
        // {
        //     text: 'POD',
        //     value: 'portOfDischargeValue',
        //     align: 'center',
        //     filterable: true
        // },
        {
          text: "Dest.",
          value: "finalDestinationValue",
          align: "center",
          width: "100px",
        },
        {
          text: "ETA",
          value: "eta",
          align: "center",
          width: "100px",
        },
        {
          text: "Carrier Ref. No",
          value: "carrierReferenceNumber",
          align: "center",
          filterable: false,
        },
        {
          text: "Shipper Ref. No",
          value: "shipperReferenceNo",
          align: "center",
          width: "200px",
          filterable: false,
        },
        {
          text: "Consignee Ref. No",
          value: "consigneeReferenceNo",
          width: "200px",
          align: "center",
          filterable: false,
        }
      ],
      loading: false,
      loadingOrganisations: false,
      order: {},
      orderModal: false,
      orders: {
          data: [],
          total: 0
      },
      organisations: {
        total: 0,
        data: [],
      },
      orderQuery: undefined,
      orderStatus: [
    { name: "Planned", icon: "schedule", color: "#F57C00" },
    { name: "In Progress", icon: "pending", color: "blue" },
    { name: "Allocated", icon: "fact_check", color: "cyan" },
    { name: "Confirmed", icon: "verified", color: "teal" },
    { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
      orderTimeout: undefined,
      params: null,
      previewModal: false,
      quickSelectDates: [
      {
        text: "Past 7 Days",
        value: "past_week",
        type: "past",
        difference: -7,
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      }
    ],
      search: null,
      searchHeaders: {},
      searchKey: 100,
      selectedBooking: {},
      selectedOrderItem: {},
      selectedHeaders: [],
      showMenu: false,
      sortBy: null,
      sortDesc: false,
      updateFilterValue: 350,
      viewBookingInformation: false,
      x: null,
      y: null
    }),
    computed: {
      calculatedHeight() {
        let height = window.innerHeight;
        return height * 0.62 + "px";
      },
      dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
      displayData() {
        let result = this.filteredResults;
        if (this.search) {
          result = result.filter(
            (x) =>
              (x.orderNo &&
                x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.shipperName &&
                x.shipperName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.consigneeName &&
                x.consigneeName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.shippingLineName &&
                x.shippingLineName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.vesselVoyage &&
                x.vesselVoyage
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.regimeCode &&
                x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.portOfLoadValue &&
                x.portOfLoadValue
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.portOfLoadCity &&
                x.portOfLoadCity
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.finalDestinationValue &&
                x.finalDestinationValue
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.finalDestinationCity &&
                x.finalDestinationCity
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.carrierReferenceNumber &&
                x.carrierReferenceNumber
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.shipperReferenceNo &&
                x.shipperReferenceNo
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.consigneeReferenceNo &&
                x.consigneeReferenceNo
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
                  (x.customerName &&
                x.customerName
                  .toLowerCase()
                  .includes(this.search.toLowerCase()))
          );
        }
        let uniqueWeeks = [...new Set(result.map((x)=>x.sailingWeek))]
        let final = []
        for(let i=0;i<uniqueWeeks.length;i++){
          let filtered = result.filter(x=>x.sailingWeek == uniqueWeeks[i])
          if (this.sortBy) {
            filtered = filtered.sort((a, b) => {
            if (this.sortDesc) {
              return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
            } else {
              return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
            }
          });
        }
          final = [...final,{
            displayType: 'header',
            title: uniqueWeeks[i] + ' - ' + filtered[0].sailingWeekYear,
            orders: filtered.length,
            containers: filtered.map(x=>x.numContainers).reduce((a,b)=>a+b,0)
          }, ...filtered ]
        }
        return final;
      },
      tableWidth() {
        let result = 0;
        this.headers.forEach((x) => {
          result += parseInt(x.width ? x.width.replace("px", "") : 150);
        });
        return result + "px";
      },
    },
    async created(){
      if (localStorage.stockBookingParams) {
      this.params = JSON.parse(localStorage.stockBookingParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    if (localStorage["orderFilters_" + this.$store.state.currentOrg.id]) {
      this.filters = JSON.parse(
        localStorage["orderFilters_" + this.$store.state.currentOrg.id]
      );
      // console.log("existing filters", this.filters)
      if (typeof this.filters != "object") {
        this.filters = {}
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if (!this.params) {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        hideCancelled: true,
        dates: [],
      };
      this.selectDate("current_week");
    }
      if (!this.filters) {
        this.filters = {};
        this.resetFilters();
      }
      this.containerTypes = await this.$API.listContainerTypes();
    },
    async mounted() {
      this.load();
    },
    methods: {
      addOrder(){
        this.order = {
          recordType: 'ORDER',
          orderStatus: 'Planned',
          status: "Draft",
          transportType: "Container",
          movementType: "EXPORT",
          type: "LOGISTICS",
          modeOfTransport: "OCEAN",
          serviceType: "FCL",
          bookingContainerItems: [],
          plannedYear: new Date().getFullYear(),
          plannedWeek: moment().isoWeek(),
        }
        this.orderModal = true
      },
      addToFilter(value, field) {
        if (this.filters[field].includes(value)) {
          this.filters[field] = this.filters[field].filter((x) => x != value);
        } else {
          this.filters[field].push(value);
        }
        this.updateFilterValue++;
        localStorage.setItem(
          "orderFilters_" + this.$store.state.currentOrg.id,
          JSON.stringify(this.filters)
        );
        let keys = Object.keys(this.filters);
        let result = [];
        keys.forEach((x) => {
          result = [
            ...result,
            ...this.filters[x].map((y) => ({
              field: x,
              fieldName: this.headers.find((z) => z.value == x).text,
              value: y,
            })),
          ];
        });
        this.filteredValues = result;
        this.calculateFilteredResults();
      },
      calculateFilteredResults() {
        let filerKeys = Object.keys(this.filters);
        let filterResult = [];
        filerKeys.forEach((x) => {
          filterResult = [
            ...filterResult,
            ...this.filters[x].map((y) => ({
              field: x,
              fieldName: this.headers.find((z) => z.value == x).text,
              value: y,
            })),
          ];
        });
        this.filteredValues = filterResult;
        let result = [...this.orders.data];
        let keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) => this.filters[key].includes(x[key]));
          }
        }
        this.filteredResults = result;
      },
      changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      this.dateKey++;
      this.getOrders();
    },
      changeSortBy(value) {
        if (this.sortBy == value) {
          if (this.sortDesc) {
            this.sortBy = null;
            this.sortDesc = false;
          } else {
            this.sortDesc = true;
          }
        } else {
          this.sortBy = value;
          this.sortDesc = false;
        }
      },
      async load() {
        this.getOrders();
        await this.getRelations();
      },
      formatDate(date) {
        let result = null;
        if (date) {
          result = dateFormat(new Date(date), "dd-mm-yyyy");
        }
        return result;
      },
      formatDateTime(date) {
        let result = null;
        if (date) {
          result = dateFormat(date, "yyyy-mm-dd HH:MM");
        }
        return result;
      },
      async getOrders() {
        if (this.orderTimeout) {
          clearTimeout(this.orderTimeout);
        }
        if (this.loading && this.orderQuery) {
          this.orderQuery.abort();
        }
        this.loading = true;
        let params = this.params;
      //   params.teams = this.selectedTeams;
      //   if (this.params.dates.length == 1) {
      //     params.dates = [
      //       moment(this.params.dates[0])
      //         .startOf("isoWeek")
      //         .toISOString()
      //         .substring(0, 10),
      //       moment(this.params.dates[0])
      //         .endOf("isoWeek")
      //         .toISOString()
      //         .substring(0, 10),
      //     ];
      //   }
        params.recordType = 'ORDER'
        // params.orderStatus = 'OPEN'
        this.orderTimeout = setTimeout(async () => {
          this.orderQuery = new AbortController();
          const signal = this.orderQuery.signal;
          this.orders = await this.$API.getAssignedStockBookings({
            params: params,
            signal,
          });
          this.calculateFilteredResults();
          this.loading = false;
        }, 500);
      },
      async getRelations() {
        this.loadingOrganisations = true;
        this.organisations = await this.$API.getRelation({
          // params: this.params,
        });
        this.loadingOrganisations = false;
      },
      selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem("stockBookingParams", JSON.stringify(this.params));
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getOrders();
    },
      statusColor(status) {
      if(status){
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else  return 'grey'
      },
      statusIcon(status) {
      if(status){
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return 'help'
      },
      bookingStatusColor(status) {
      if(status){
        let color = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else  return 'grey'
      },
      bookingStatusIcon(status) {
      if(status){
        let find = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return 'help'
      },
      headerOptions(value) {
        let result = [
          ...new Set(
            this.orders.data
              // .filter(x => x[value])
              .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
          ),
        ];
        let uniqueValues = [...new Set(result.map((x) => x.value))];
        if (this.searchHeaders[value]) {
          uniqueValues = uniqueValues.filter(
            (x) =>
              x &&
              x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
          );
        }
        result = uniqueValues.map((x) => result.find((y) => y.value === x));
        // if (value == 'status') {
        //     result.push({ name: 'Unallocated', value: null })
        // }
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return result;
      },
      resetFilters() {
        this.filters = {};
        let keys = this.headers.map((x) => x.value);
        keys.forEach((x) => {
          this.searchHeaders[x] = null;
          this.filters[x] = [];
        });
        this.filteredValues = [];
        this.updateFilterValue++;
        localStorage.removeItem(
          "orderFilters_" + this.$store.state.currentOrg.id
        );
        this.calculateFilteredResults();
      },
      setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem("stockBookingParams", JSON.stringify(this.params));
      this.getOrders();
      this.dateMenu = false;
    },
    async viewBookingDetail(reference) {
      this.selectedBooking = await this.$API.getBookingDetail(reference)
      this.viewBookingInformation = true
    },
      async viewOrder(item) {
        this.bookingRequestItem = item
        this.containers = await this.$API.getOrderContainersBasic(item.id);
        this.previewModal = true
      },
      viewItem(e, item) {
        e.preventDefault();
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.selectedOrderItem = item;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      },
    }
  };
  </script>
  
  <style scoped>
  .container {
    width: 100%;
    height: 70vh;
    margin: auto;
    /* outline: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>